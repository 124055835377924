@use '../../../../variables';

.sdd-mandate-container-loading {
    text-align: center;
}

.sdd-mandate-container h2 {
    font-size: 1.2em;
    font-weight: 400;
}

.sdd-mandate-form-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin-bottom: 20px;
}

.sdd-mandate-container .button-container {
    text-align: center;
    margin-top: 30px;

    > button {
        max-width: 320px;
        width: 100%;
    }
}

@media screen and (max-width: variables.$md) {
    .sdd-mandate-form-section {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: variables.$sm) {
    .sdd-mandate-form-section {
        grid-template-columns: 1fr;
    }
}
