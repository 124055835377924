.payment-header-container {
    text-align: center;
    margin-bottom: 35px;
}

img.payment-header-logo {
    max-width: 155px;
    max-height: 68px;
    width: 100%;
    margin-bottom: 20px;
}

.payment-header-summary {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-row-gap: 5px;

    p {
        margin: 0;
    }
}

.payment-header-amount {
    font-size: 18px;
    margin-top: 35px;
}
